import React, { useEffect, useState } from 'react';
import { onSnapshot, query, collectionGroup, collection, orderBy, limit, where } from 'firebase/firestore';
import db from './firebase';
import LogoImage from "./components/logoImage";
import $ from 'jquery'
import Outlet from "./components/outlet";

const App = () => {
    const [expandedArticles, setExpandedArticles] = useState({});
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [outlets, setOutlets] = useState([]);
    const [visible, setVisible] = useState([]);
    //fetch the outlet data
    useEffect(() => {
        const fetchOutlets = async () => {
            try {
                const q = query(collection(db, 'Outlets'));
                const unsubscribe = await onSnapshot(q, (querySnapshot) => {
                    const stack = querySnapshot.docs.map((outlet) => ({
                        id: outlet.id,
                        'visible': true,
                        ...outlet.data(),
                    }));
                    setOutlets(stack);
                });

                return () => unsubscribe()

            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };

        fetchOutlets().then();
    }, []);
    //fetch the article data
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (visible.length === 0) {
                    return; // Don't execute the query if visible is empty
                }

                const q = query(
                    collectionGroup(db, 'articles'),
                    limit(35),
                    orderBy('timestamp', 'desc'),
                    where('outlet', 'in', visible)
                );
                const unsubscribe = await onSnapshot(q, (querySnapshot) => {
                    const stack = querySnapshot.docs.map((article) => ({
                        id: article.id,
                        index: article.id.replace('/', ''),
                        ...article.data(),
                    }));
                    setArticles(stack);
                    setLoading(false);
                });

                return () => unsubscribe();
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };

        fetchData().then();
    }, [visible]);
    //update a visible state off the outlet data
    useEffect(() => {
        const newVisible = outlets.filter((outlet) => outlet.visible).map((outlet) => outlet.outlet);
        setVisible(newVisible);
    }, [outlets]);


    const handleExpandedToggle = (articleId) => {
        toggleIndices(articleId).then((response) => {
            const $article = $('div[data-ref="' + articleId + '"]')
            const scrollIndex= ($article.offset()['top'])
            window.scrollTo({
                top: scrollIndex - 20,
                behavior: 'smooth',
            })
        })

    };

    async function toggleIndices(articleId) {
        let response = await setExpandedArticles((prevState) => {
            const updatedState = { ...prevState };
            Object.entries(updatedState).forEach(([key,value]) => {
                if(key!=articleId) {
                    updatedState[key] = false
                }
            })
            updatedState[articleId] = !updatedState[articleId];
            return updatedState;
        });
        return response
    }

    //check for any articles whose expanded state is being tracked, but are no longer on  the page, and delete them
    const expandedKeys = (Object.keys(expandedArticles))
    expandedKeys.map( (key) => {
        if(articles.some(article => article.index === key)){
            return
        } else{
            let expandedCopy = expandedArticles
            delete expandedCopy[key]
            setExpandedArticles(expandedCopy)
            return
        }
    } )
    return (
        <div className='p-6'>
            <div className="form-control flex flex-col sm:flex-row w-full justify-center items-end sm:items-center sm:gap-10">
                {outlets.map((outlet, index) => {
                    return(
                        <div key={index}>
                            <Outlet setOutlets={setOutlets} outlets={outlets} outlet={outlet} ></Outlet>
                        </div>
                    )

                })
                }
            </div>
            {loading ? (
                <p>Loading...</p>
            ) : articles.length > 0 && visible.length>0 ? (
                <div className='join join-vertical w-full'>
                    {articles.map((article, index) =>
                        (
                        <div
                            data-ref={article.index}
                            key={index}
                            className={`whitespace-pre-wrap border-t join-item flex items-center min-h-[40vh] xl:min-h-[80vh] relative md:p-10 mt-10`}

                        >
                            <button onClick={() => handleExpandedToggle(article.index)} className="leading-[3rem] flex bg-secondary leading-12 align-middle items-center h-6 w-6 lg:h-12 lg:w-12 justify-center text-white absolute top-0 right-0 text:xl lg:text-4xl">{!expandedArticles[article.index] ? '+' : '-' }</button>


                                <div className="text-xl mb-8 font-medium w-full flex flex-col xl:flex-row justify-center items-start xl:items-center">

                                    <div className="w-5/6 xl:w-3/4 flex items-start lg:items-center flex-col pt-4 md:pt-0">
                                        {article.imgSrc ? (
                                            <img className={'w-full xl:w-2/3'} src={article.imgSrc} alt={article.imgCaption} />
                                        ) : (
                                            <div className="w-full xl:w-2/3">
                                                <LogoImage collection={article.outlet} lookup={outlets} classes={'w-full h-auto'} />
                                            </div>
                                        )}
                                        <div className={`pt-4 flex flex-col w-full justify-start md:justify-center items-start ${!expandedArticles[article.index] ? 'md:items-center' : 'xl:justify-center xl:items-center'}`}>
                                            <h3 className={`${!expandedArticles[article.index] ? 'text-2xl md:text-center' : 'text-sm xl:text-center'} lg:pt-4 text-left md:text-center p-10}`}>{!expandedArticles[article.index] ? article.headline : article.imgCaption}</h3>

                                                {!expandedArticles[article.index] ?
                                                    <div className={'w-1/3 lg:w-1/6 pb-4 mt-4'}>
                                                        <LogoImage collection={article.outlet} lookup={outlets} classes={'w-full h-auto'} />
                                                    </div> : ''}
                                            <p className={`text-sm text-left ${!expandedArticles[article.index] ? 'md:text-center' : 'xl:text-center'}`}>{String(article.timestamp.toDate())}</p>
                                        </div>
                                    </div>

                                    <div className={`flex flex-col pl-0 p-2 lg:p-10 lg:pl-0 xl:pl-10 ${!expandedArticles[article.index] ? 'hidden' : '' }`}>
                                            <h3 className={`text-2xl lg:text-4xl pt-4`}>{article.headline}</h3>
                                        {!['Reuters'].includes(article.outlet) ? <h4 className="text-xl lg:text-2xl pt-2">{article.tagline}</h4> : ''}
                                            <br/>
                                            <p className={'text-base'}>{article.copy}</p>
                                            <br/>
                                            <a className={'text-xl'} target='_blank' rel='noreferrer' href={article.url}>
                                                Read Article
                                                <br/>
                                                <div className="w-40">
                                                <LogoImage collection={article.outlet} lookup={outlets} />
                                                </div>
                                            </a>
                                            <br />
                                        </div>
                            </div>


                            {/* Render other article data here */}
                        </div>
                    ))}
                </div>
            ) : (
                <p>No articles found.</p>
            )}
        </div>

    );
};

export default App;
