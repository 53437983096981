global.Buffer = global.Buffer || require('buffer').Buffer
const LogoImage = ({lookup, collection, classes= ''}) => {
    const outlet = lookup.filter(obj => {
        return obj.outlet === collection
    })
    try {
        const outletProps = outlet[0]
        const image = outletProps.logo;
        const buff = new Buffer(image);
        const base64data = buff.toString('base64');
        return (<img className={`min-h-0 ` + classes} src={`data:image/svg+xml;base64,${base64data}`} alt={outletProps.outlet + ' Logo | Copyright: ' + outletProps.url} />)
    } catch (error) {
        console.error(error)
    }
}

export default LogoImage