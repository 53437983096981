import firebaseConfig from './firebaseConfig'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Initialize Firebase

const app = initializeApp(firebaseConfig)
// Export firestore database
// It will be imported into your react app whenever it is needed

const db = getFirestore(app)
export default db