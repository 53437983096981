import React from "react";
import LogoImage from "./logoImage";

const Outlet = ({outlet, outlets, setOutlets}) => {
    function handleClick(event, id) {
        const outletId = id; // Assuming the id of the outlet is passed to the event handler
        const updatedOutlets = outlets.map((outlet) => {
            if (outlet.id === outletId) {
                return {
                    ...outlet,
                    visible: !outlet.visible,
                };
            }
            return outlet;
        });
        setOutlets(updatedOutlets);
    }
        return (
            <label className="cursor-pointer label ml-2">
                <div className=""><LogoImage classes={`h-6 w-auto md:h-12 md:w-auto`} collection={outlet.outlet} lookup={outlets}></LogoImage>
                </div>
                    <input onChange={(event) => handleClick(event, outlet.id)} type="checkbox" checked={outlet.visible ? 'checked' : ''} className="checkbox checkbox-primary ml-2" />
            </label>
        )
}

export default Outlet